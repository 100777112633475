/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.header-pdf {
  margin-top: 5%;
}
.fc-event-time, .fc-event-title {
  padding: 0 1px;
  white-space: normal;
  }

  .fc-daygrid-event {
    white-space: normal !important;
    align-items: normal !important;
  }

  .appmap {
    height: 500px;
    width: 500px;
  }

  .keclogo{
    font-size:24px;
    font-size:3vw;
  }
  .kablogo{
    font-size:2vw;
  }
  .alamatlogo{
    font-size:1.5vw;
  }
  .kodeposlogo{
    font-size:1.7vw;
  }
  #tls{
   text-align:right; 
  }
  .alamat-tujuan{
    margin-left:50%;
  }
  .garis1{
    border-top:3px solid black;
    height: 2px;
    border-bottom:1px solid black;
  }
  #logo{
    margin: auto;
    margin-left: 20%;
    margin-right: auto;
  }
  #tempat-tgl{
    margin-left:120px;
  }
  #camat{
    text-align:center;
  }
  #nama-camat{
    margin-top:100px;
    text-align:center;
  }

/* Adjust the spacing between day names and day numbers */
.custom-datepicker {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 10px; /* Adjust the margin as needed */
  }
}